export const environment = {
  production: false,
  apiUrl: window['env']['apiUrl'] || 'https://sti.iomtdev.bbraun.io/api',
  loginUrl: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/oauth2/v2.0/authorize',
  logoutUrl: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/oauth2/v2.0/logout',
  issuer: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/v2.0',
  clientId: '60f12f3a-9db7-4e66-ac97-c9f5b4321f4d',
  trackingUi: {
    enable: true,
    reportingUrl: 'https://eum-green-saas.instana.io',
  },
  instanaApiKey: window['env']['instanaApiKey'] || '',
};
