import { createAction, props } from '@ngrx/store';

import { UserInfo } from '../../modules/shared/interfaces/user/user-info.interface';

export const GET_USER_INFO = '[USER] GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = '[USER] GET_USER_INFO_SUCCESS';
export const IS_USER_INFO_AVAILABLE = '[USER] HAS_USER_INFO';
export const CLEANUP_USER_INFO = '[USER] CLEANUP_USER_INFO';

export const GetUserInfoAction = createAction(GET_USER_INFO);

export const GetUserInfoSuccessAction = createAction(GET_USER_INFO_SUCCESS, props<{ payload: UserInfo }>());

export const IsUserInfoAvailable = createAction(IS_USER_INFO_AVAILABLE);

export const CleanupUserInfoAction = createAction(CLEANUP_USER_INFO);
