import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private readonly router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: (ev: HttpEvent<any>) => ev,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.router.navigate(['error'], {
              queryParams: { errorStatusCode: err.status, errorCodeIoMT: err?.error?.errorCode }
            });
          }
        }
      })
    );
  }
}
