import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './modules/auth/guard/auth.guard';
import { LoginComponent } from './modules/auth/login/login.component';
import { RedirectEndpointComponent } from './modules/auth/redirect-endpoint/redirect-endpoint.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/overview/overview.module').then((m) => m.OverviewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'device-details',
    loadChildren: () => import('./modules/device-details/device-details.module').then(m => m.DeviceDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/shared/components/error-page/error-page.module').then((m) => m.ErrorPageModule),
    canActivate: [AuthGuard]
  },
  {path: 'auth/callback', component: RedirectEndpointComponent},
  {path: 'login', component: LoginComponent},
  {path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // The initial navigation is not performed. The location listener is set up before the root component gets created
    // allowing the auth logic to be initialized.
    initialNavigation: 'disabled',
    relativeLinkResolution: 'legacy',
    // Restores the previous scroll position on backward navigation, else sets the position to the anchor if one is
    // provided, or sets the scroll position to [0, 0] (forward navigation).
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
