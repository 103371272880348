import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthConfig, OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';

import { authCodeFlowConfig } from './config/auth-code-flow.config';
import { authModuleConfig } from './config/auth-module.config';
import { RedirectEndpointComponent } from './redirect-endpoint/redirect-endpoint.component';


@NgModule({
  declarations: [
    RedirectEndpointComponent
  ],
  imports: [
    OAuthModule.forRoot()
  ],
  exports: [RedirectEndpointComponent]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: authCodeFlowConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig }
      ]
    };
  };

  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}
