import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private INITIAL_REDIRECT_STATE = 'initialRedirectState';

  constructor(private readonly router: Router, private readonly oauthService: OAuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      return true;
    } else {
      localStorage.setItem(this.INITIAL_REDIRECT_STATE, window.location.pathname + window.location.search);
      this.router.navigate(['/login']);
      return false;
    }
  }

}
