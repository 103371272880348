import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '../../../../environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.issuer,

  logoutUrl: environment.logoutUrl,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/auth/callback',

  silentRefreshRedirectUri: window.location.origin + '/silent-refresh',

  postLogoutRedirectUri: window.location.origin,

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: environment.clientId,

  responseType: 'code',

  disableAtHashCheck: true,

  clearHashAfterLogin: false,

  preserveRequestedRoute: true,

  // Set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a use-case specific one
  // to delete scope: 'openid profile email offline_access api'.
  // However offline_access is not supported by ping federate so it returns
  // invalid_scope when trying to refresh the token.
  scope: 'openid profile email',

  showDebugInformation: true,

  strictDiscoveryDocumentValidation: false,

  skipIssuerCheck: true,

  // refreshes auth token only once
  timeoutFactor: 0.9
};
