import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Subject, takeUntil } from 'rxjs';

import { authCodeFlowConfig } from './modules/auth/config/auth-code-flow.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  constructor(private readonly oauthService: OAuthService,
              private readonly router: Router) {
    this.configure();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private configure(): void {
    this.oauthService.configure(authCodeFlowConfig);

    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(
      () => {
        this.router.initialNavigation();
        this.setupAutomaticLogoutInCaseOfTokenExpiry();
      }
    );
  }

  private setupAutomaticLogoutInCaseOfTokenExpiry(): void {
    if (!this.oauthService.events) {
      return;
    }

    this.oauthService.events
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: OAuthEvent) => {
        if (event.type === 'token_expires') {
          this.router.navigateByUrl('login');
        }
      });
  }
}
