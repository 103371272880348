import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  showSplash = true;

  private readonly SHOW_SPLASH_MS = 1000;

  ngOnInit(): void {
    setTimeout(() => this.showSplash = false, this.SHOW_SPLASH_MS);
  }

}
