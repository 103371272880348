import { AfterViewChecked, Component } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-redirect-endpoint',
  templateUrl: './redirect-endpoint.component.html'
})
export class RedirectEndpointComponent implements AfterViewChecked{
  wasNotCalled = true;
  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router
  ) {}

  ngAfterViewChecked(): void {
    if (this.oauthService.getIdToken() && this.oauthService.getAccessToken() && this.wasNotCalled) {

      this.wasNotCalled = false;
      this.router.navigateByUrl('/');
    }
  }
}
