import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from './user.actions';
import { initialState, UserState } from './user.state';

export const userFeatureKey = 'user';

const userReducer = createReducer<UserState>(
  initialState,
  on(UserActions.GetUserInfoSuccessAction, (state: UserState, { payload }) => {
    return {
      ...state,
      userInfo: payload
    };
  }),
  on(UserActions.CleanupUserInfoAction, (state: UserState) => {
    return {
      userInfo: null
    };
  })
);

export function UserReducer(state: UserState | undefined, action: Action): UserState {
  return userReducer(state, action);
}
