import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserInfo } from '../../modules/shared/interfaces/user/user-info.interface';
import { UserService } from '../../modules/shared/services/user/user.service';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService
  ) { }

  getUserInfo$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.GET_USER_INFO),
    switchMap(() =>
      this.userService.getUserInfo().pipe(
        map((userInfo: UserInfo) => {
          return UserActions.GetUserInfoSuccessAction({ payload: userInfo });
        }),
        catchError((error: Error) => {
          return of(UserActions.CleanupUserInfoAction());
        })
      )
    )
  ));
}
