import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { UserInfo } from '../../interfaces/user/user-info.interface';
import { BaseHttpService } from '../http/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseHttpService {

  getUserInfo(): Observable<UserInfo> {
    return this.get<UserInfo>(`${environment.apiUrl}/v1/user-info`);
  }
}
